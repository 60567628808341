import React from "react";
import { NavMobile } from "./Nav_mobile";
import miniature1 from './../assets/miniatures/mianiature1.png'
import miniature2 from './../assets/miniatures/mianiature2.png'
import miniature3 from './../assets/miniatures/mianiature3.png'
import miniature4 from './../assets/miniatures/mianiature4.png'
import miniature5 from './../assets/miniatures/mianiature5.png'
import miniature6 from './../assets/miniatures/mianiature6.png'
import miniature7 from './../assets/miniatures/mianiature7.png'
import miniature8 from './../assets/miniatures/mianiature8.png'
import bulletin1 from './../assets/Dla_firm_1.pdf'
import bulletin2 from './../assets/Dla_firm_2.pdf'
import bulletin3 from './../assets/Dla_firm_3.pdf'
import bulletin4 from './../assets/Dla_firm_4.pdf'
import bulletin5 from './../assets/Dla_firm_5.pdf'
import bulletin6 from './../assets/Dla_firm_6.pdf'
import bulletin7 from './../assets/Dla_firm_7.pdf'
import bulletin8 from './../assets/Dla_firm_8.pdf'
import { DownloadSection } from "./DownloadSection";
import { Footer } from "./Footer";

function Bulletin() {

  const downloadSection = {
    title: 'Do pobrania',
    names:['Biuletyn - PL', 'Bulletin - ENG', 'Formularz zapotrzebowania SPAWACZ', 'Formularz zapotrzebowania MONTER'],
    miniatures: [miniature1, miniature4, miniature2, miniature3],
    pdfs: [bulletin1, bulletin4, bulletin2, bulletin3],
  }

  const references = {
    title: 'Referencje',
    names:[],
    miniatures: [miniature5, miniature6, miniature7, miniature8],
    pdfs: [bulletin5, bulletin6, bulletin7, bulletin8],
  }

  return (
    <section className="bulletin">
      <NavMobile />
      <div className={"bulletin__wrapper"}>
        <DownloadSection content={downloadSection}/>
        <DownloadSection content={references}/>
      </div>
      <Footer />
    </section>
  );
}

export { Bulletin };
