import React from "react";
import { DownloadItem } from "./DownloadItem";
export function DownloadSection(props) {

    const { title, names, miniatures, pdfs } = props.content;
    return (
        <section className="download__section">
            <h2> {title} </h2>
            <div className="download__section--wrapper">
                {miniatures.map((e,i) => { return (<DownloadItem key={i} title={names[i]} miniature={miniatures[i]} pdf={pdfs[i]}/>) })}
            </div>
        </section>
    );
}