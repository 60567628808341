import React, { Component } from "react";
import { CarouselElement } from "./CarouselElement"

import EPG from "./../assets/logos/EPG_logo.png";
import granly from "./../assets/logos/granly-logo.png";
import crist from "./../assets/logos/crist_logo.png";
import stalkon from "./../assets/logos/stalkon_logo.png";
import baltic from "./../assets/logos/baltic_logo.png";
import mostostal from "./../assets/logos/mostostal_logo.svg";
import tdcc from "./../assets/logos/tdcc_logo.png";
import dr from "./../assets/logos/d&r_logo.png";
import dw from "./../assets/logos/d&w_logo.png";
import stmr from "./../assets/logos/stmr_logo.png";
import slupca from "./../assets/logos/mostostalSlupca_logo.png";
import vogen from "./../assets/logos/vogen_logo.png";

class Carousel extends Component {
  state = {
    slides: [
      stalkon,
      EPG,
      granly,
      crist,
      baltic,
      mostostal,
      tdcc,
      dr,
      dw,
      stmr,
      slupca,
      vogen
    ],
    prevSlide: 0,
    currentSlide: 1,
    nextSlide: 2,
  };

  componentDidMount() {
    this.intervalID = setInterval(() => {
      if (window.innerWidth < 600) {
        if (this.state.currentSlide >= this.state.slides.length - 1) {
          this.setState({ currentSlide: 0 });
        } else this.setState({ currentSlide: this.state.currentSlide + 1 });
      } else {
        if (this.state.prevSlide >= this.state.slides.length - 3) {
          this.setState({ prevSlide: 0 });
        } else {
          this.setState({ prevSlide: this.state.prevSlide + 3 });
        }
        if (this.state.currentSlide >= this.state.slides.length - 2) {
          this.setState({ currentSlide: 1 });
        } else {
          this.setState({ currentSlide: this.state.currentSlide + 3 });
        }
        if (this.state.nextSlide >= this.state.slides.length - 1) {
          this.setState({ nextSlide: 2 });
        } else {
          this.setState({ nextSlide: this.state.nextSlide + 3 });
        }
      }
    }, 5000);
  }

  render() {
    return (
      <section className="carousel">
        <h2>Zaufali nam:</h2>
        {window.innerWidth > 1000 ?
          <div className="carousel__container">
            <CarouselElement height={this.props.height} width={this.props.width} background={this.state.slides[this.state.prevSlide]} />
            <CarouselElement height={this.props.height} width={this.props.width} background={this.state.slides[this.state.currentSlide]} />
            <CarouselElement height={this.props.height} width={this.props.width} background={this.state.slides[this.state.nextSlide]} />
          </div> : 
          <div className="carousel__container">
            <CarouselElement height={this.props.height} width={this.props.width} background={this.state.slides[this.state.currentSlide]} />
          </div>
        }
      </section>
    );
  }
}

export { Carousel };
