import React from "react";

export function DownloadItem(props) {

    return (
        <div className="download__item">
            <a href={props.pdf} target={"_blank"}>
                <h3>{props.title}</h3>
                <div className="download__item--miniature" style={{ backgroundImage: `url(${props.miniature})` }}></div>
            </a>
            <a href={props.pdf} download>
                <button className="download__item--button">Pobierz</button>
            </a>
        </div>
    );
}