import React from "react";

export function CarouselElement(props) {

const style = {
    height:`${props.height}`,
    width:`${props.width}`,
    backgroundImage: `url(${props.background})`
}
    return (
        <div className="carousel__item" style={style}></div>
    );
}